import { defineStore } from 'pinia'

import SubjectArea = models.server.api.international.subjectArea.Data
import { record } from 'rrweb'
interface State {
  siteCoreSubjectArea: SubjectArea
  subjectAreas: string[]
  locations: string[]
}

export const useIntSubjectAreaLocationStore = defineStore(
  'intSubjectAreaLocation',
  {
    state: (): State => ({
      siteCoreSubjectArea: {} as SubjectArea,
      subjectAreas: [],
      locations: []
    }),

    actions: {
      async fetchSiteCoreSubjectAreas(slug: string) {
        const { getSubjectArea } = useInternationalApi()

        const { data } = await getSubjectArea(slug)
        this.siteCoreSubjectArea = data || {}

        return this.siteCoreSubjectArea
      },
      async fetchSubjectAreasLocations() {
        const { fetchSubjectAreasLocations } = useIntCourseSearch()

        if (this.subjectAreas.length && this.locations.length) {
          return { subjectAreas: this.subjectAreas, locations: this.locations }
        }

        const { subjectAreas, locations } = await fetchSubjectAreasLocations()
        this.subjectAreas = subjectAreas
        this.locations = locations

        return { subjectAreas, locations }
      }
    },

    getters: {
      subjectAreaImage: (state) => {
        const search = state.siteCoreSubjectArea?.search
        return search && Object.keys(search).length
          ? {
              results: search.results?.items?.map((x) => {
                return {
                  name: x.item.name,
                  image: {
                    src: x.item.image?.src,
                    alt: x.item.image?.alt
                  }
                }
              })
            }
          : undefined
      },
      entryRequirements: (state) => {
        const entryRequirement = state.siteCoreSubjectArea?.entryRequirement
        return entryRequirement && Object.keys(entryRequirement).length
          ? {
              heading: entryRequirement.heading?.value,
              subTitle: entryRequirement.subTitle?.value
            }
          : undefined
      },
      feeAndLocation: (state) => {
        const feeAndLocation = state.siteCoreSubjectArea?.feeAndLocation
        return feeAndLocation && Object.keys(feeAndLocation).length
          ? {
              heading: feeAndLocation.heading?.value,
              subTitle: feeAndLocation.subTitle?.value
            }
          : undefined
      }
    }
  }
)
